import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { Refine } from "@refinedev/core";
import { theme } from "style/theme";
import "@aws-amplify/ui-react/styles.css";
import "@aws-amplify/ui-react/styles.css";
import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import { RefineSnackbarProvider } from "@refinedev/mui";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useAuthenticator, withAuthenticator } from "@aws-amplify/ui-react";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import routerBindings, {
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import TagIcon from "@mui/icons-material/Tag";
import FilterListIcon from "@mui/icons-material/FilterList";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import AppRouter from "routing";
import HiveIcon from "@mui/icons-material/Hive";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { ReactComponent as CharityIcon } from "./assets/icons/charity.svg";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PaidIcon from "@mui/icons-material/Paid";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notificationProvider } from "providers/notification-provider/notification-provider";
import { dataProvider } from "providers/rest-data-provider";
import SettingsIcon from "@mui/icons-material/Settings";
import { useIdleTimer } from "react-idle-timer";

Amplify.configure(awsExports);

function App() {
  const { signOut } = useAuthenticator((context) => [context.route]);
  const handleOnIdle = () => {
    signOut()
  };


  useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 minutes
    onIdle: handleOnIdle,
    debounce: 500,
  });
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <RefineSnackbarProvider>
          <Refine
            routerProvider={routerBindings}
            dataProvider={dataProvider(awsExports.endpoints[0].endpoint)}
            notificationProvider={notificationProvider}
            resources={[
              {
                name: "grant-vestments",
                list: "/grant-vestments/list",
                show: "/grant-vestments/:id",
                create: "/grant-vestments/create",
                edit: "/grant-vestments/update/:id",
                icon: <HiveIcon />,
                meta: { label: "GrantVestment" },
              },
              {
                name: "charities",
                list: "/charities/list",
                icon: <CharityIcon />,
                meta: { label: "Charities" },
              },
              {
                name: "investments",
                list: "/investments/list",
                show: "/investments/history",
                create: "/investments/create",
                edit: "/investments/edit/:id",
                icon: <CurrencyExchangeIcon />,
                meta: { label: "Investments" },
              },
              {
                name: "funds",
                list: "/fund-accounts/list",
                show: "/fund-accounts/show/:id",
                icon: <AccountBalanceOutlinedIcon />,
                meta: { label: "Fund Accounts" },
              },
              {
                name: "logs",
                list: "/fund/log",
                icon: <AccountBalanceOutlinedIcon />,
                meta: { label: "Fund Accounts Logs" },
              },
              {
                name: "Users",
                icon: <GroupsIcon />,
              },
              {
                name: "users/status/ACCEPTED",
                list: "users/status/accepted",
                show: "/users/:sub",
                icon: <GroupIcon fontSize="small" />,
                meta: { parent: "Users", label: "Accepted" },
              },
              {
                name: "users/status/PENDING",
                list: "/users/status/Pending",
                icon: <GroupAddIcon fontSize="small" />,
                meta: { parent: "Users", label: "Pending" },
              },

              {
                name: "users/status/REJECTED",
                list: "users/status/rejected",
                icon: <PersonAddDisabledIcon fontSize="small" />,
                meta: { parent: "Users", label: "Rejected" },
              },
              {
                name: "Interest",
                icon: <FilterListIcon />,
              },
              {
                name: "categories",
                list: "/categories",
                create: "/categories/create",
                show: "/categories/:id",
                edit: "/categories/:id/edit",
                icon: <WorkspacesIcon fontSize="small" />,
                meta: {
                  label: "Categories",
                  parent: "Interest",
                },
              },
              {
                name: "tags",
                list: "/tags",
                create: "/tags/create",
                show: "/tags/:id",
                edit: "/tags/:id/edit",
                icon: <TagIcon fontSize="small" />,
                meta: { label: "Tags", parent: "Interest" },
              },

              {
                name: "Contribution-transaction-list",
                icon: <VolunteerActivismIcon />,
                meta: { label: "Contribution" },
              },
              {
                name: "contribution-transactions/pending",
                list: "/contribution-transactions/status/pending",
                show: "/contribution-transactions/pending/:id",
                icon: <PendingActionsOutlinedIcon fontSize="small" />,
                meta: {
                  parent: "Contribution-transaction-list",
                  label: "Pending",
                },
              },

              {
                name: "contribution-transactions",
                list: "/contribution-transactions",
                create: "/contribution-transactions/create",
                show: "/contribution-transactions/accepted/:id",
                icon: <CheckCircleOutlineOutlinedIcon fontSize="small" />,
                meta: {
                  parent: "Contribution-transaction-list",
                  label: "Accepted",
                },
              },
              {
                name: "contribution-transactions/processed",
                list: "/contribution-transactions/status/processed",
                show: "/contribution-transactions/processed/:id",
                icon: <VerifiedOutlinedIcon fontSize="small" />,
                meta: {
                  parent: "Contribution-transaction-list",
                  label: "Processed",
                },
              },

              {
                name: "contribution-transactions/rejected",
                list: "/contribution-transactions/status/rejected",
                show: "/contribution-transactions/rejected/:id",
                icon: <CancelOutlinedIcon fontSize="small" />,
                meta: {
                  parent: "Contribution-transaction-list",
                  label: "Rejected",
                },
              },
              {
                name: "contribution-transactions/canceled",
                list: "/contribution-transactions/status/canceled",
                show: "/contribution-transactions/canceled/:id",
                icon: <DoDisturbOutlinedIcon fontSize="small" />,
                meta: {
                  parent: "Contribution-transaction-list",
                  label: "canceled",
                },
              },
              {
                name: "grant vestments requests",
                icon: <TrendingUpOutlinedIcon />,
                meta: { label: "GrantVest" },
              },
              {
                name: "grant-vestments-requests/status/PENDING",
                list: "/grant-vestments-requests/status/pending",
                icon: <PendingActionsOutlinedIcon fontSize="small" />,
                meta: { parent: "grant vestments requests", label: "Pending" },
              },
              {
                name: "grant-vestments-requests/status/APPROVED",
                list: "grant-vestments-requests/status/approved",
                icon: <CheckCircleOutlineOutlinedIcon fontSize="small" />,
                meta: { parent: "grant vestments requests", label: "Approved" },
              },
              {
                name: "grant-vestments-requests/status/PROCESSED",
                list: "grant-vestments-requests/status/processed",
                icon: <CheckCircleOutlineOutlinedIcon fontSize="small" />,
                meta: {
                  parent: "grant vestments requests",
                  label: "Processed",
                },
              },
              {
                name: "grant-vestments-requests/status/REJECTED",
                list: "grant-vestments-requests/status/rejected",
                icon: <CancelOutlinedIcon fontSize="small" />,
                meta: { parent: "grant vestments requests", label: "Rejected" },
              },

              {
                name: "Investment Requests",
                icon: <RequestPageIcon />,
                meta: { label: "InvestRequests" },
              },
              {
                name: "investments-requests/status/PENDING",
                list: "investment-requests/status/pending",
                show: "/investment-requests/pending/:id",
                icon: <PendingActionsOutlinedIcon fontSize="small" />,
                meta: { parent: "Investment Requests", label: "Pending" },
              },
              {
                name: "investments-requests/status/ACCEPTED",
                list: "investment-requests/status/accepted",
                show: "/investment-requests/accepted/:id",
                icon: <CheckCircleOutlineOutlinedIcon fontSize="small" />,
                meta: { parent: "Investment Requests", label: "Accepted" },
              },
              {
                name: "investments-requests/status/REJECTED",
                list: "investment-requests/status/rejected",
                show: "/investment-requests/rejected/:id",
                icon: <CancelOutlinedIcon fontSize="small" />,
                meta: { parent: "Investment Requests", label: "Rejected" },
              },
              {
                name: "Grant Requests",
                icon: <PaidIcon />,
                meta: { label: "GrantRequests" },
              },
              {
                name: "grant-requests/status/PENDING",
                list: "grant-requests/status/pending",
                show: "/grant-requests/pending/:id",
                icon: <PendingActionsOutlinedIcon fontSize="small" />,
                meta: { parent: "Grant Requests", label: "Pending" },
              },
              {
                name: "grant-requests/status/APPROVED",
                list: "grant-requests/status/approved",
                show: "/grant-requests/approved/:id",
                icon: <CheckCircleOutlineOutlinedIcon fontSize="small" />,
                meta: { parent: "Grant Requests", label: "Approved" },
              },
              {
                name: "grant-requests/status/REJECTED",
                list: "grant-requests/status/rejected",
                show: "/grant-requests/rejected/:id",
                icon: <CancelOutlinedIcon fontSize="small" />,
                meta: { parent: "Grant Requests", label: "Rejected" },
              },
              /* {
                name: "grant-requests/status/CANCELED",
                list: "grant-requests/status/canceled",
                show: "/grant-requests/canceled/:id",
                icon: <DoDisturbOutlinedIcon fontSize="small" />,
                meta: { parent: "Grant Requests", label: "Cancelled" },
              }, */
              {
                name: "grant-requests/status/SCHEDULED",
                list: "grant-requests/status/scheduled",
                show: "/grant-requests/scheduled/:id",
                icon: <ScheduleIcon fontSize="small" />,
                meta: { parent: "Grant Requests", label: "Scheduled" },
              },
              {
                name: "grant-requests/status/PROCESSED",
                list: "grant-requests/status/processed",
                show: "/grant-requests/processed/:id",
                icon: <CheckCircleOutlineOutlinedIcon fontSize="small" />,
                meta: { parent: "Grant Requests", label: "Processed" },
              },
              {
                name: "Recommended Charities",
                icon: <CharityIcon />,
                meta: { label: "Charities Requests" },
              },
              {
                name: "recommended-charities/status/PENDING",
                list: "recommended-charities/status/pending",
                show: "/recommended-charities/pending/:id",
                icon: <PendingActionsOutlinedIcon fontSize="small" />,
                meta: { parent: "Recommended Charities", label: "Pending" },
              },
              {
                name: "recommended-charities/status/APPROVED",
                list: "recommended-charities/status/approved",
                show: "/recommended-charities/accepted/:id",
                icon: <CheckCircleOutlineOutlinedIcon fontSize="small" />,
                meta: { parent: "Recommended Charities", label: "Approved" },
              },
              {
                name: "neta-settings",
                icon: <SettingsIcon />,
                list: "/neta-settings",
                edit: "/neta-settings",
                meta: { label: "Settings" },
              },
            ]}
            options={{
              warnWhenUnsavedChanges: true,
              syncWithLocation: true,
            }}
          >
            <AppRouter />
            <ToastContainer />
            <UnsavedChangesNotifier />
          </Refine>
        </RefineSnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
