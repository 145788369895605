import React from "react";
import { List } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { LogCard } from "components/card/log-card";


export const FundBalanceLogList: React.FC<IResourceComponentsProps> = () => {


  return (
    <>
      <List title="Fund Balance Change Log">
        <LogCard></LogCard>
      </List>
    </>
  );
};
